import Layout from "../components/layout";
import React from "react";
import Seo from "../components/seo";
// import Firstfold from "../partials/Firstfold";

const About = () => {
  return (
    <Layout>
      <Seo title="Know about my skills and motivations to turn to UX after Engineering!" />
      <section className="md:grid md:grid-cols-12 md:gap-6 max-w-6xl mx-auto px-5 sm:px-6 md:pt-32 md:pb-8 py-8 mb-0">
        <div className="md:col-span-6 lg:col-span-6 md:order-2">
          <img
            src="../static/8e2ea743f36aaa40c3133b74db9f06ff/about_me.png"
            alt="mypotrait"
            className="mix-blend-multiply rounded-xl"
          />
        </div>
        <div className="md:col-span-6 lg:col-span-6 md:my-32 my-8 md:order-1">
          <div className="items-center h-full flex-initial">
            <h3 className="text-sm mb-2 uppercase text-teal-700 font-bold">
              Product Design | B2B | SaaS
            </h3>
            <p className="text-xl mb-2">
              Product designer with 6+ years of experience focused on crafting a
              meaningful user experience for enterprise software and SaaS
              products.
            </p>
            <h3 className="text-sm mt-8 uppercase text-gray-400">
              Domain Experience
            </h3>
            <div className="flex flex-row flex-wrap">
              <div className="mr-2 my-1 bg-teal-600 text-white text-xs font-semibold px-2.5 py-1 rounded dark:bg-blue-200 dark:text-blue-800">
                Logistics and E-commerce
              </div>
              <div className="mr-2 my-1 bg-blue-600 text-white text-xs font-semibold px-2.5 py-1 rounded dark:bg-blue-200 dark:text-blue-800">
                HRMS
              </div>
              <div className="mr-2  my-1 bg-fuchsia-300 text-white text-xs font-semibold px-2.5 py-1 rounded dark:bg-blue-200 dark:text-blue-800">
                ERP
              </div>
              <div className="mr-2 my-1 bg-pink-600 text-white text-xs font-semibold px-2.5 py-1 rounded dark:bg-blue-200 dark:text-blue-800">
                No-Code Development
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="md:grid md:grid-cols-12 md:gap-6 max-w-6xl mx-auto px-5 sm:px-6 md:pb-32 md:pt-8 py-8 mb-0">
        <div className="md:col-span-6 lg:col-span-6">
          <img
            src="../static/37ab8c5a8d860fb9e9df97172389437f/reading_book.png"
            alt="mypotrait"
            className="mix-blend-multiply rounded-xl"
          />
        </div>
        <div className="md:col-span-6 lg:col-span-6 md:my-32 my-8">
          <div className="items-center h-full flex-initial">
            <h3 className="text-sm mb-2 uppercase text-teal-700 font-bold">
              When i am not designing
            </h3>
            <p className="text-xl mb-2">
              I read non-frictional books, learning programming and tools like
              blender.
            </p>
            <h3 className="text-sm mt-8 uppercase text-gray-400">
              Other interests
            </h3>
            <div className="flex flex-row flex-wrap">
              <div className="mr-2 my-1 bg-teal-600 text-white text-xs font-semibold px-2.5 py-1 rounded dark:bg-blue-200 dark:text-blue-800">
                Music
              </div>
              <div className="mr-2 my-1 bg-blue-600 text-white text-xs font-semibold px-2.5 py-1 rounded dark:bg-blue-200 dark:text-blue-800">
                Jogging
              </div>
              <div className="mr-2  my-1 bg-fuchsia-300 text-white text-xs font-semibold px-2.5 py-1 rounded dark:bg-blue-200 dark:text-blue-800">
                Doodle
              </div>
              <div className="mr-2 my-1 bg-pink-600 text-white text-xs font-semibold px-2.5 py-1 rounded dark:bg-blue-200 dark:text-blue-800">
                Learning new tools
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default About;
